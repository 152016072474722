import React from "react";
import Col from "../Col/Col";
import Button from "../Button/button";

interface CtaBarProps {
  title?: string;
  buttonText?: string;
  buttonLink?: string;
}

const CtaBar = ({ title, buttonText, buttonLink }: CtaBarProps) => {
  return (
    <div className="prizing__ready">
      <Col size="50">
        <h3>{title}</h3>
      </Col>
      <Col size="50">
        <Button
          {...{
            variant: "blue",
            link: `${buttonLink}`,
            text: `${buttonText}`,
          }}
        />
      </Col>
    </div>
  );
};

export default CtaBar;
